<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Hybridløsning er bedre enn utelukkende fysisk forelesning.</h2> <!-- Expression/Question -->

    <vsa-list>
      <vsa-item class="trekkspell">
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              De siste årene har det vært mye digitale forelesinger, grunnet Korona. Nå er vi tilbake til «normalen»,
              men mange studenter ønsker fortsatt hybridløsninger eller muligheten for å ha digitale forelesinger,
              grunnet for eksempel sykdom.</p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>



</template>

<script>

import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  },
}
</script>
<style scoped>
</style>